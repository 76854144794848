import { useMemo, useState } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import SvgColor from 'src/components/svg-color';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  search: icon('ic_search'),
  addInfluencer: icon('ic_booking'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();
  const [userType, setuserType] = useState(localStorage.getItem("userType") || localStorage.getItem("sessionUserType"))
  const role = useSelector((state) => state.role.role);

  const sessionUserType = localStorage.getItem("sessionUserType")

  const influencers_list_icon = 'ph:users-three-fill';
  const dashboard_icon = 'ic:outline-dashboard';
  const upload_influencer_icon = 'ph:user-circle-plus-fill';
  const settings_icon = 'ic:outline-settings';
  const my_report_icon = 'ph:user-circle-fill';
  const agency_icon = 'hugeicons:new-office';

  const data = useMemo(() => {
    const items = [

      {
        subheader: t('overview'),
        items: [
          {
            title: (userType === "influencer" || sessionUserType === "influencer") ? 'My Report' : t('Creators'),
            path: (userType === "influencer" || sessionUserType === "influencer") ? `${paths.blank(localStorage.getItem("influencerId"))}` : paths.creators,
            icon: (userType === "influencer" || sessionUserType === "influencer") ? my_report_icon : influencers_list_icon,
          }
        ]
      },
      {
        subheader: t('settings'),
        items: [
          {
            title: t('Profile'),
            path: paths.user.account,
            icon: settings_icon,
          },
        ],
      },
    ];
    if (role === 'admin' || role === 'member') {
      items[0].items.unshift({
        title: t('Dashboard'),
        path: paths.root,
        icon: dashboard_icon,
      });
    }
    if (role === 'admin' || role === 'member') {
      items[0].items.push({
        title: t('Add Creator(s)'),
        path: paths.uploadInfluencer,
        icon: upload_influencer_icon,
      });
    }

    if (role === 'admin') {
      items[0].items.push({
        title: t('My Organization'),
        path: paths.myAgency,
        icon: agency_icon,
      });
    }

    return items;
  }, [userType, sessionUserType]);

  return data;
}
