import axios from 'axios';
import React, { createContext, useContext } from 'react';
import { HOST_API } from 'src/config-global';
import axiosInstance from 'src/utils/axios';


class CServerAPI {
  constructor(baseUrl, client = null) {
    this._baseUrl = baseUrl;
    this._client = axiosInstance;
    this._categoriesMapping = null;
    this._categoriesMappingObject = null;
  }

  _withAuthorization(headers = null) {
    // Add Authorization header if token is present
    headers = headers || {};
    const token = localStorage.getItem('accessToken');
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    return headers;
  }

  _useJson(headers = null) {
    headers = headers || {};
    headers['Content-Type'] = 'application/json';
    return headers;
  }

  async defaultLogin(data) {
    const apiUrl = `/api/auth/login/`;

    try {
      const response = await fetch(`${HOST_API}${apiUrl}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...this._withAuthorization(this._useJson()),
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.errors && Array.isArray(errorData.errors)) {
          errorData.errors.forEach((err) => {
            const detail = err.detail || 'Unknown error occurred';
            throw new Error(detail);
          });
        } else {
          console.error('Unexpected error format');
          throw new Error('Unexpected error format');
        }
      }
      const result = await response.json();
      return result;

    } catch (error) {
      console.error('Request failed:', error.message);
      throw error;
    }
  }

  async defaultRegistration(data) {
    const apiUrl = `/api/auth/registration/`;

    try {
      const response = await fetch(`${HOST_API}${apiUrl}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...this._withAuthorization(this._useJson()),
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.errors && Array.isArray(errorData.errors)) {
          errorData.errors.forEach((err) => {
            const detail = err.detail || 'Unknown error occurred';
            throw new Error(detail);
          });
        } else {
          console.error('Unexpected error format');
          throw new Error('Unexpected error format');
        }
      }
      const result = await response.json();
      return result;

    } catch (error) {
      console.error('Request failed:', error.message);
      throw error;
    }
  }

  async verification(data) {
    const apiUrl = `/api/auth/registration/verify-email/`;

    try {
      const response = await fetch(`${HOST_API}${apiUrl}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...this._withAuthorization(this._useJson()),
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.errors && Array.isArray(errorData.errors)) {
          errorData.errors.forEach((err) => {
            const detail = err.detail || 'Unknown error occurred';
            throw new Error(detail);
          });
        } else {
          console.error('Unexpected error format');
          throw new Error('Unexpected error format');
        }
      }
      const result = await response.json();
      return result;

    } catch (error) {
      console.error('Request failed:', error.message);
      throw new Error(error);
    }
  }

  async authMe() {
    const apiUrl = `/api/auth/me`;

    try {
      const response = await fetch(`${HOST_API}${apiUrl}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...this._withAuthorization(this._useJson()),
        },
      });
      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.errors && Array.isArray(errorData.errors)) {
          errorData.errors.forEach((err) => {
            const detail = err.detail || 'Unknown error occurred';
            throw new Error(detail);
          });
        } else {
          console.error('Unexpected error format');
          throw new Error('Unexpected error format');
        }
      }
      const result = await response.json();
      return result;

    } catch (error) {
      console.error('Request failed:', error.message);
      throw new Error(error);
    }
  }

  async fetchLists() {
    const apiUrl = `/api/lists/`;

    try {
      const response = await fetch(`${HOST_API}${apiUrl}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...this._withAuthorization(this._useJson()),
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.errors && Array.isArray(errorData.errors)) {
          errorData.errors.forEach((err) => {
            const detail = err.detail || 'Unknown error occurred';
            throw new Error(detail);
          });
        } else {
          console.error('Unexpected error format');
          throw new Error('Unexpected error format');
        }
      }

      return await response.json();
    } catch (error) {
      throw new Error(error.message || 'Something went wrong');
    }
  }

  async fetchPosts(influencerId, category, page, year = null, month = null, platforms) {
    try {
      const apiUrl = year && month
        ? `/api/influencer/${influencerId}/posts/?year=${year}&month=${month}&media_category=${category}&page=${page}&platforms=${platforms.join(",").toLowerCase()}`
        : `/api/influencer/${influencerId}/posts/?media_category=${category}&page=${page}&platforms=${platforms.join(",").toLowerCase()}`;

      const response = await fetch(`${HOST_API}${apiUrl}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...this._withAuthorization(this._useJson()),
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.errors && Array.isArray(errorData.errors)) {
          errorData.errors.forEach((err) => {
            const detail = err.detail || 'Unknown error occurred';
            throw new Error(detail);
          });
        } else {
          console.error('Unexpected error format');
          throw new Error('Unexpected error format');
        }
      }

      return await response.json();
    } catch (error) {
      throw new Error(error.message || 'Something went wrong');
    }
  }

  async fetchPostsCompliance(influencerId, category, page, year = null, month = null, platforms) {
    try {
      const apiUrl = year && month
        ? `/api/influencer/${influencerId}/compliance-posts/?year=${year}&month=${month}&compliance_key=${category}&page=${page}&platforms=${platforms.join(",").toLowerCase()}`
        : `/api/influencer/${influencerId}/compliance-posts/?compliance_key=${category}&page=${page}&platforms=${platforms.join(",").toLowerCase()}`;

      const response = await fetch(`${HOST_API}${apiUrl}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...this._withAuthorization(this._useJson()),
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.errors && Array.isArray(errorData.errors)) {
          errorData.errors.forEach((err) => {
            const detail = err.detail || 'Unknown error occurred';
            throw new Error(detail);
          });
        } else {
          console.error('Unexpected error format');
          throw new Error('Unexpected error format');
        }
      }

      return await response.json();
    } catch (error) {
      throw new Error(error.message || 'Something went wrong');
    }
  }

  async fetchPostsBrand(influencerId, category, page, year = null, month = null, platforms, partnership) {
    try {
      const apiUrl = year && month
        ? `/api/influencer/${influencerId}/brands-posts/?year=${year}&month=${month}&brand_id=${category}&page=${page}&platforms=${platforms.join(",").toLowerCase()}&paid_partnership=${partnership}`
        : `/api/influencer/${influencerId}/brands-posts/?brand_id=${category}&page=${page}&platforms=${platforms.join(",").toLowerCase()}&paid_partnership=${partnership}`;

      const response = await fetch(`${HOST_API}${apiUrl}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...this._withAuthorization(this._useJson()),
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.errors && Array.isArray(errorData.errors)) {
          errorData.errors.forEach((err) => {
            const detail = err.detail || 'Unknown error occurred';
            throw new Error(detail);
          });
        } else {
          console.error('Unexpected error format');
          throw new Error('Unexpected error format');
        }
      }

      return await response.json();
    } catch (error) {
      throw new Error(error.message || 'Something went wrong');
    }
  }

  async fetchTimeline(influencerId, category, platforms) {
    try {
      const apiUrl = `/api/influencer/${influencerId}/timeline/?media_category=${category}&platforms=${platforms.join(",").toLowerCase()}`;

      const response = await fetch(`${HOST_API}${apiUrl}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...this._withAuthorization(this._useJson()),
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.errors && Array.isArray(errorData.errors)) {
          errorData.errors.forEach((err) => {
            const detail = err.detail || 'Unknown error occurred';
            throw new Error(detail);
          });
        } else {
          console.error('Unexpected error format');
          throw new Error('Unexpected error format');
        }
      }

      return await response.json();
    } catch (error) {
      throw new Error(error.message || 'Something went wrong');
    }
  }

  async fetchTimelinCompliance(influencerId, category, platforms) {
    try {
      const apiUrl = `/api/influencer/${influencerId}/compliance-timeline/?compliance_key=${category}&platforms=${platforms.join(",").toLowerCase()}`;

      const response = await fetch(`${HOST_API}${apiUrl}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...this._withAuthorization(this._useJson()),
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.errors && Array.isArray(errorData.errors)) {
          errorData.errors.forEach((err) => {
            const detail = err.detail || 'Unknown error occurred';
            throw new Error(detail);
          });
        } else {
          console.error('Unexpected error format');
          throw new Error('Unexpected error format');
        }
      }

      return await response.json();
    } catch (error) {
      throw new Error(error.message || 'Something went wrong');
    }
  }

  async fetchTimelinBrand(influencerId, category, platforms, partnership) {
    try {
      const apiUrl = `/api/influencer/${influencerId}/brands-timeline/?brand_id=${category}&platforms=${platforms.join(",").toLowerCase()}&paid_partnership=${partnership}`;

      const response = await fetch(`${HOST_API}${apiUrl}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...this._withAuthorization(this._useJson()),
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.errors && Array.isArray(errorData.errors)) {
          errorData.errors.forEach((err) => {
            const detail = err.detail || 'Unknown error occurred';
            throw new Error(detail);
          });
        } else {
          console.error('Unexpected error format');
          throw new Error('Unexpected error format');
        }
      }

      return await response.json();
    } catch (error) {
      throw new Error(error.message || 'Something went wrong');
    }
  }

  async fetchSearchDataPdf(limit, payloadDataFinal, Authorization) {
    try {
      if (!limit) return;

      const headers = {
        Authorization,
        'Content-Type': 'application/json',
      };

      const apiUrl = `${this._baseUrl}/api/influencer/search?limit=${limit}`;

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers,
        body: JSON.stringify(payloadDataFinal),
      });

      if (response.headers.get('Content-Type').includes('application/pdf')) {
        return response.blob();
      } else {
        const errorData = await response.json();
        throw new Error(errorData);
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  async _fetchCategoriesMapping() {
    /*
    Fetch the categories mapping from the server and cache it.
    */
    if (this._categoriesMapping) {
      return this._categoriesMapping;
    }
    const apiUrl = `/api/categories-mapping/`;
    const response = await this._client.get(apiUrl, {
      headers: this._withAuthorization(this._useJson())
    });
    this._categoriesMapping = response.data;
    return response.data;
  }

  async registerInvitedMember(data) {
    const apiUrl = `${this._baseUrl}/api/invitations/accept/`;
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...this._withAuthorization(this._useJson()),
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.errors && Array.isArray(errorData.errors)) {
          errorData.errors.forEach((err) => {
            const detail = err.detail || 'Unknown error occurred';
            throw new Error(detail);
          });
        } else {
          console.error('Unexpected error format');
          throw new Error('Unexpected error format');
        }
      }

      return await response.json();
    } catch (error) {
      throw new Error(error.message || 'Something went wrong');
    }
  }

  async getOrganization(organizationId) {
    const apiUrl = `/api/organizations/${organizationId}/`;
    try {
      const response = await fetch(`${HOST_API}${apiUrl}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...this._withAuthorization(this._useJson()),
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      throw new Error(error.message || 'Something went wrong');
    }
  }

  async getMembersFromList(listId) {
    const apiUrl = `/api/lists/${listId}/members/`;
    try {
      const response = await fetch(`${HOST_API}${apiUrl}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...this._withAuthorization(this._useJson()),
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      throw new Error(error.message || 'Something went wrong');
    }
  }

  async getOrganizations() {
    try {
      const apiUrl = `/api/organizations/`;
      const response = await fetch(`${HOST_API}${apiUrl}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...this._withAuthorization(this._useJson()),
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async updateMember(orgId, id, data) {
    const apiUrl = `/api/organizations/${orgId}/members/${id}/`;

    try {
      const response = await fetch(`${HOST_API}${apiUrl}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          ...this._withAuthorization(this._useJson()),
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.errors && Array.isArray(errorData.errors)) {
          errorData.errors.forEach((err) => {
            const detail = err.detail || 'Unknown error occurred';
            throw new Error(detail);
          });
        } else {
          console.error('Unexpected error format');
          throw new Error('Unexpected error format');
        }
      }
      const result = await response.json();
      return result;

    } catch (error) {
      console.error('Request failed:', error.message);
      throw new Error(error.message);
    }
  }

  async setMonitoringOn(creator_id, isCrowled) {
    const apiUrl = `/api/influencer/${creator_id}/toggle-to-be-crawled/`;

    try {
      const response = await fetch(`${HOST_API}${apiUrl}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          ...this._withAuthorization(this._useJson()),
        },
        body: JSON.stringify({ to_be_crawled: isCrowled }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.errors && Array.isArray(errorData.errors)) {
          errorData.errors.forEach((err) => {
            const detail = err.detail || 'Unknown error occurred';
            throw new Error(detail);
          });
        } else {
          console.error('Unexpected error format');
          throw new Error('Unexpected error format');
        }
      }
      const result = await response.json();
      return result;

    } catch (error) {
      console.error('Request failed:', error.message);
      throw new Error(error.message);
    }
  }

  async searchCreators(org_id, influencer_id, platforms, q, exact = false, sort_by, page, page_size, list_id) {

    const filtersWithInfluencer = list_id ? { organization_id: org_id, influencer_id, platforms, list_id } : { organization_id: org_id, influencer_id, platforms };
    const filtersWithoutInfluencer = list_id ? { organization_id: org_id, platforms, list_id } : { organization_id: org_id, platforms };
    
    const apiUrl = `/api/search/influencers/`;
    try {
      const response = await fetch(`${HOST_API}${apiUrl}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...this._withAuthorization(this._useJson()),
        },
        body: JSON.stringify(influencer_id ?
          { q, exact, sort_by, page, page_size, filters: filtersWithInfluencer } : 
          { q, exact, sort_by, page, page_size, filters: filtersWithoutInfluencer }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.errors && Array.isArray(errorData.errors)) {
          errorData.errors.forEach((err) => {
            const detail = err.detail || 'Unknown error occurred';
            throw new Error(detail);
          });
        } else {
          console.error('Unexpected error format');
          throw new Error('Unexpected error format');
        }
      }
      return await response.json();
    } catch (error) {
      throw new Error(error.message || 'Something went wrong');
    }
  }

  async searchAutosuggest(org_id, influencer_id = null, q, list_id) {
    const apiUrl = `/api/search/autosuggest/`;
    try {
      const response = await fetch(`${HOST_API}${apiUrl}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...this._withAuthorization(this._useJson()),
        },
        body: JSON.stringify(influencer_id ? { q, organization_id: org_id, influencer_id, list_id } : { q, organization_id: org_id, list_id }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.errors && Array.isArray(errorData.errors)) {
          errorData.errors.forEach((err) => {
            const detail = err.detail || 'Unknown error occurred';
            throw new Error(detail);
          });
        } else {
          console.error('Unexpected error format');
          throw new Error('Unexpected error format');
        }
      }
      return await response.json();
    } catch (error) {
      throw new Error(error.message || 'Something went wrong');
    }
  }

  async searchPosts(org_id, influencer_id, platforms, q, exact = false, sort_by, page, page_size, list_id) {
    
    const filtersWithInfluencer = list_id ? { organization_id: org_id, influencer_id, platforms, list_id } : { organization_id: org_id, influencer_id, platforms };
    const filtersWithoutInfluencer = list_id ? { organization_id: org_id, platforms, list_id } : { organization_id: org_id, platforms };

    const apiUrl = `/api/search/posts/`;
    try {
      const response = await fetch(`${HOST_API}${apiUrl}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...this._withAuthorization(this._useJson()),
        },
        body: JSON.stringify(influencer_id ? 
          { q, exact, sort_by, page, page_size, filters: filtersWithInfluencer } : 
          { q, exact, sort_by, page, page_size, filters: filtersWithoutInfluencer }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.errors && Array.isArray(errorData.errors)) {
          errorData.errors.forEach((err) => {
            const detail = err.detail || 'Unknown error occurred';
            throw new Error(detail);
          });
        } else {
          console.error('Unexpected error format');
          throw new Error('Unexpected error format');
        }
      }
      return await response.json();
    } catch (error) {
      throw new Error(error.message || 'Something went wrong');
    }
  }

  async toggleNotifications(orgId, memberId) {
    const apiUrl = `/api/organizations/${orgId}/members/${memberId}/toggle-notifications/`;
    try {
      const response = await fetch(`${HOST_API}${apiUrl}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...this._withAuthorization(this._useJson()),
        },
        body: JSON.stringify({}),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      throw new Error(error.message || 'Something went wrong');
    }
  }

  async addMember(data) {
    const apiUrl = `/api/organizations/invite/`;

    try {
      const response = await fetch(`${HOST_API}${apiUrl}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...this._withAuthorization(this._useJson()),
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.errors && Array.isArray(errorData.errors)) {
          errorData.errors.forEach((err) => {
            const detail = err.detail || 'Unknown error occurred';
            throw new Error(detail);
          });
        } else {
          console.error('Unexpected error format');
          throw new Error('Unexpected error format');
        }
      }
      const result = await response.json();
      return result;

    } catch (error) {
      console.error('Request failed:', error.message);
      throw new Error(error.message);
    }
  }

  async deleteMember(orgId, id) {
    const apiUrl = `/api/organizations/${orgId}/members/${id}/`;

    try {
      const response = await fetch(`${HOST_API}${apiUrl}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          ...this._withAuthorization(this._useJson()),
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.errors && Array.isArray(errorData.errors)) {
          errorData.errors.forEach((err) => {
            const detail = err.detail || 'Unknown error occurred';
            throw new Error(detail);
          });
        } else {
          console.error('Unexpected error format');
          throw new Error('Unexpected error format');
        }
      }

    } catch (error) {
      throw new Error(error.message || 'Something went wrong');
    }
  }

  async removeListMember(listId, id) {
    const apiUrl = `/api/lists/${listId}/members/${id}/`;

    try {
      const response = await fetch(`${HOST_API}${apiUrl}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          ...this._withAuthorization(this._useJson()),
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.errors && Array.isArray(errorData.errors)) {
          errorData.errors.forEach((err) => {
            const detail = err.detail || 'Unknown error occurred';
            throw new Error(detail);
          });
        } else {
          console.error('Unexpected error format');
          throw new Error('Unexpected error format');
        }
      }
    } catch (error) {
      throw new Error(error.message || 'Something went wrong');
    }
  }



  async _mappedCategories(data) {
    /*
    Use data from the "/api/categories-mapping" endpoint to map the categories.
    */
    if (!data) {
      return data;
    }
    // check if is an object
    if (typeof data !== 'object') {
      throw new Error('Data should be an object');
    }
    const mapping = await this._fetchCategoriesMapping();
    // Map the categories and add extra fields
    const categories = {};
    for (const [key, item] of Object.entries(data)) {
      const extra = mapping[key] || {};
      categories[key] = {
        ...item,
        ...extra
      };
    }
    return categories;
  }

  async updateInfluencer({ id: influencerId, ...data }) {
    /*
    This function will update the influencer data in the server.
    The data object should contain the fields:
      id, age, name, region, pronoun, gender,
      profile_pic, social_profiles, summary,
      is_summary_generated
    */
    try {
      if (!influencerId) {
        throw new Error('Influencer ID is required');
      }
      const apiUrl = `/api/influencer/${influencerId}/`;
      const response = await fetch(`${HOST_API}${apiUrl}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          ...this._withAuthorization(this._useJson()),
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.errors && Array.isArray(errorData.errors)) {
          errorData.errors.forEach((err) => {
            const detail = err.detail || 'Unknown error occurred';
            throw new Error(detail);
          });
        } else {
          console.error('Unexpected error format');
          throw new Error('Unexpected error format');
        }
      }

      return await response.json();
    } catch (error) {
      throw new Error(error);
    }
  }

  async getInfluencerData(influencerId) {
    if (!influencerId) {
      throw new Error('Influencer ID is required');
    }

    const apiUrl = `/api/influencer/${influencerId}/`;

    try {
      const response = await fetch(`${HOST_API}${apiUrl}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...this._withAuthorization(this._useJson()),
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.errors && Array.isArray(errorData.errors)) {
          errorData.errors.forEach((err) => {
            const detail = err.detail || 'Unknown error occurred';
            throw new Error(detail);
          });
        } else {
          console.error('Unexpected error format');
          throw new Error('Unexpected error format');
        }
      }

      const data = await response.json();
      const mappedScores = await this.getCategoriesByIds([influencerId]);
      data.scores = mappedScores[0][influencerId];
      return data;

    } catch (error) {
      // window.location.replace('/403');
      throw new Error(error);
    }
  }

  async getInfluencerDataBrands(influencerId) {
    if (!influencerId) {
      throw new Error('Influencer ID is required');
    }

    const apiUrl = `/api/influencer/${influencerId}/brands/`;

    try {
      const response = await fetch(`${HOST_API}${apiUrl}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...this._withAuthorization(this._useJson()),
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.errors && Array.isArray(errorData.errors)) {
          errorData.errors.forEach((err) => {
            const detail = err.detail || 'Unknown error occurred';
            throw new Error(detail);
          });
        } else {
          console.error('Unexpected error format');
          throw new Error('Unexpected error format');
        }
      }

      return await response.json();

    } catch (error) {
      throw new Error(error);
    }
  }

  async postInfluencerDataBrands(influencerId, data) {
    if (!influencerId) {
      throw new Error('Influencer ID is required');
    }

    const apiUrl = `/api/influencer/${influencerId}/brands/update/`;

    try {
      const response = await fetch(`${HOST_API}${apiUrl}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          ...this._withAuthorization(this._useJson()),
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

    } catch (error) {
      throw new Error(error);
    }
  }

  async getInfluencersDataHeatmap(isFiltered, limit, value, isRotated, page, platforms, setFilterData, region, gender, followersReach, sliderValues, influencerDisplayName, isMyAgency, brands) {
    const payloadDataFinal = {};
    const platformPayloadData = {};

    if (region) payloadDataFinal.region = region;
    if (gender) payloadDataFinal.gender = gender;
    if (followersReach) payloadDataFinal.followers_reach = Number(followersReach);

    ['facebook', 'instagram', 'twitter', 'tiktok', 'youtube'].forEach(platform => {
      if (platforms[platform]) platformPayloadData[platform] = platforms[platform];
    });
    if (Object.keys(platformPayloadData).length) payloadDataFinal.platform = platformPayloadData;

    const filteredValues = Object.fromEntries(Object.entries(sliderValues).filter(([key, value]) => value < 100));
    if (Object.keys(filteredValues).length) payloadDataFinal.content_alignment = filteredValues;
    if (brands?.length) payloadDataFinal.brands = brands;
    if (influencerDisplayName !== '') payloadDataFinal.name = influencerDisplayName;

    const payloadDataFinalFilter = {
      platform: platformPayloadData,
      region,
      followers_reach: Number(followersReach),
      gender,
      name: influencerDisplayName,
      content_alignment: filteredValues ? filteredValues : {},
      brands
    };
    setFilterData(payloadDataFinalFilter);

    const body = JSON.stringify(payloadDataFinal);
    const apiUrl = `/api/influencer/search/heatmap/?limit=${limit}&page=${page || 1}&ordering=${isRotated ? '' : '-'}${value}`;

    const response = await fetch(`${HOST_API}${apiUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...this._withAuthorization(this._useJson()),
      },
      body
    });

    const data = await response.json();

    const influencerIds = data.influencers.map(influencer => influencer.id);
    const mappedScores = await this.getCategoriesByIds(influencerIds);

    data.influencers = data.influencers.map(influencer => {
      const newScores = mappedScores.find(score => score[influencer.id]);
      if (newScores) {
        influencer.scores = newScores[influencer.id];
      }
      return influencer;
    });

    return data;
  }

  async getInfluencersData(isFiltered, limit, value, isRotated, page, platforms, setFilterData, region, gender, followersReach, sliderValues, influencerDisplayName, isMyAgency, brands) {
    const payloadDataFinal = {};
    const platformPayloadData = {};

    if (region) payloadDataFinal.region = region;
    if (gender) payloadDataFinal.gender = gender;
    if (followersReach) payloadDataFinal.followers_reach = Number(followersReach);

    ['facebook', 'instagram', 'twitter', 'tiktok', 'youtube'].forEach(platform => {
      if (platforms[platform]) platformPayloadData[platform] = platforms[platform];
    });
    if (Object.keys(platformPayloadData).length) payloadDataFinal.platform = platformPayloadData;

    const filteredValues = Object.fromEntries(Object.entries(sliderValues).filter(([key, value]) => value < 100));
    if (Object.keys(filteredValues).length) payloadDataFinal.content_alignment = filteredValues;
    if (brands.length) payloadDataFinal.brands = brands;
    if (influencerDisplayName !== '') payloadDataFinal.name = influencerDisplayName;

    const payloadDataFinalFilter = {
      platform: platformPayloadData,
      region,
      followers_reach: Number(followersReach),
      gender,
      name: influencerDisplayName,
      content_alignment: filteredValues ? filteredValues : {},
      brands
    };
    setFilterData(payloadDataFinalFilter);

    const body = JSON.stringify(payloadDataFinal);
    const apiUrl = `/api/influencer/search/?limit=${limit}&page=${page || 1}&ordering=${isRotated ? '' : '-'}${value}`;

    const response = await fetch(`${HOST_API}${apiUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...this._withAuthorization(this._useJson()),
      },
      body
    });

    const data = await response.json();

    const influencerIds = data.influencers.map(influencer => influencer.id);
    const mappedScores = await this.getCategoriesByIds(influencerIds);

    data.influencers = data.influencers.map(influencer => {
      const newScores = mappedScores.find(score => score[influencer.id]);
      if (newScores) {
        influencer.scores = newScores[influencer.id];
      }
      return influencer;
    });

    return data;
  }


  async getCategoriesByIds(influencer_ids) {
    if (!influencer_ids) {
      throw new Error('Influencers ID is required');
    }
    if (influencer_ids.length === 0) {
      return;
    }

    const apiUrl = `/api/influencer/categories-mapping/`;
    const body = JSON.stringify({ influencer_ids });

    const response = await fetch(`${HOST_API}${apiUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...this._withAuthorization(this._useJson()),
      },
      body
    });

    if (!response.ok) {
      throw new Error('Failed to fetch categories');
    }

    const data = await response.json();
    return data;
  }

  async mappedCategoriesByIds(categories) {
    if (!categories) {
      throw new Error('Categories ID is required');
    }

    const apiUrl = `/api/influencer/categories-mapping/`;
    const response = await fetch(`${HOST_API}${apiUrl}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...this._withAuthorization(this._useJson()),
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch mapped categories');
    }

    const data = await response.json();
    return data;
  }

  async fetchInfluencerPdf(influencerId) {
    if (!influencerId) {
      throw new Error('Influencer ID is required');
    }

    const headers = this._withAuthorization();
    const apiUrl = `/api/influencer/${influencerId}/?pdf=true`;

    const response = await fetch(`${HOST_API}${apiUrl}`, {
      method: 'GET',
      headers: headers
    });

    if (!response.ok) {
      throw new Error('Failed to fetch influencer PDF');
    }

    if (response.headers.get('content-type').includes('application/pdf')) {
      const blob = await response.blob();
      return blob;
    }
    const text = await response.text();
    const data = JSON.parse(text);
    throw new Error(data.message || 'Unknown error');
  }

  async addToList({ listId, influencerId }) {
    if (!listId) {
      throw new Error('List ID is required');
    }
    if (!influencerId) {
      throw new Error('Influencer ID is required');
    }

    const apiUrl = `/api/lists/${listId}/influencers/`;
    const body = JSON.stringify({ influencer_ids: [influencerId] });

    const response = await fetch(`${HOST_API}${apiUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...this._withAuthorization(this._useJson()),
      },
      body
    });

    if (!response.ok) {
      throw new Error('Failed to add influencer to list');
    }

    const data = await response.json();
    return data;
  }

  async removeFromList({ listId, influencerId }) {
    if (!listId) {
      throw new Error('List ID is required');
    }
    if (!influencerId) {
      throw new Error('Influencer ID is required');
    }

    const apiUrl = `/api/lists/${listId}/influencers/${influencerId}/`;
    const response = await fetch(`${HOST_API}${apiUrl}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        ...this._withAuthorization(this._useJson()),
      },
    });

    if (!response.ok) {
      throw new Error('Failed to remove influencer from list');
    }

    const data = await response.json();
    return data;
  }

  async changeBookmark(influencerId) {
    if (!influencerId) {
      throw new Error('Influencer ID is required');
    }

    const apiUrl = `/api/lists/influencers/${influencerId}/toggle-bookmark/`;
    const response = await fetch(`${HOST_API}${apiUrl}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        ...this._withAuthorization(this._useJson()),
      },
    });

    if (!response.ok) {
      throw new Error('Failed to change bookmark status');
    }

    const data = await response.json();
    return data.is_bookmarked;
  }


  async mapping() {
    if (!this._categoriesMappingObject) { // if not already fetched
      const flatData = await this._fetchCategoriesMapping();
      // data is like: {category: {title, description, parent, children, is_leaf}}
      // convert it to a nested structure:
      //   title - item title
      //   description - item description
      //   children - array of nested items

      let hierarchy = {};
      let nodes = {};

      // Initialize nodes and their structure
      for (let key in flatData) {
        nodes[key] = {
          title: flatData[key].title,
          description: flatData[key].description,
          children: {}
        };
      }

      // Build the hierarchy
      for (let key in flatData) {
        let parent = flatData[key].parent;
        if (parent === null) {
          hierarchy[key] = nodes[key];
        } else {
          nodes[parent].children[key] = nodes[key];
        }
      }

      this._categoriesMappingObject = hierarchy;
    }

    return this._categoriesMappingObject;
  }

  async userDashboard() {
    const apiUrl = `/stats/organization-dashboard/`;
    const response = await fetch(`${HOST_API}${apiUrl}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...this._withAuthorization(this._useJson()),
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch dashboard data');
    }

    const data = await response.json();
    return data;
  }

  async findInList({ isFiltered, listId, filters, page, order_by, limit }) {
    const apiUrl = `/api/lists/${listId}/influencers/search/?page=${page}&ordering=${order_by}&limit=${limit}`;
    const body = JSON.stringify({ filters: filters });

    const response = await fetch(`${HOST_API}${apiUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...this._withAuthorization(this._useJson()),
      },
      body
    });

    if (!response.ok) {
      throw new Error('Failed to fetch list of influencers');
    }

    const data = await response.json();

    const influencerIds = data.results.map(influencer => influencer.id);

    const mappedScores = await this.getCategoriesByIds(influencerIds);

    data.results = data.results.map(influencer => {
      const newScores = mappedScores.find(score => score[influencer.id]);
      if (newScores) {
        influencer.scores = newScores[influencer.id];
      }
      return influencer;
    });

    return data;
  }

  async createNewList(data) {
    const apiUrl = `/api/lists/`;
    const body = JSON.stringify(data);

    const response = await fetch(`${HOST_API}${apiUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...this._withAuthorization(this._useJson()),
      },
      body
    });

    if (!response.ok) {
      throw new Error('Failed to create new list');
    }

    const responseData = await response.json();
    return responseData;
  }

  async renameList(listId, data) {
    const apiUrl = `/api/lists/${listId}/`;
    const body = JSON.stringify(data);

    const response = await fetch(`${HOST_API}${apiUrl}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        ...this._withAuthorization(this._useJson()),
      },
      body
    });

    if (!response.ok) {
      throw new Error('Failed to rename list');
    }

    const responseData = await response.json();
    return responseData;
  }

  async getActiveOrganization() {
    const apiUrl = `/api/organizations/active-org/`;
    const response = await fetch(`${HOST_API}${apiUrl}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...this._withAuthorization(this._useJson()),
      },
    });

    if (!response.ok) {
      throw new Error('Failed to get active organization');
    }

    const data = await response.json();
    return data;
  }

  async activateOgranization(orgId) {
    const apiUrl = `/api/organizations/active-org/`;
    const body = JSON.stringify({ org_id: orgId });

    const response = await fetch(`${HOST_API}${apiUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...this._withAuthorization(this._useJson()),
      },
      body
    });

    if (!response.ok) {
      throw new Error('Failed to activate organization');
    }

    const data = await response.json();
    return data;
  }

  async activateRole(roleId) {
    const apiUrl = `/api/organizations/active-role/`;
    const body = JSON.stringify({ role_id: roleId });

    const response = await fetch(`${HOST_API}${apiUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...this._withAuthorization(this._useJson()),
      },
      body
    });

    if (!response.ok) {
      throw new Error('Failed to activate role');
    }

    const data = await response.json();
    return data;
  }

  async deleteList(listId) {
    const apiUrl = `/api/lists/${listId}/`;

    const response = await fetch(`${HOST_API}${apiUrl}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        ...this._withAuthorization(this._useJson()),
      },
    });

    if (!response.ok) {
      throw new Error('Failed to delete list');
    }

    const data = await response.json();
    return data;
  }

}


const ServerAPIContext = createContext();

export const useServerAPI = () => {
  return useContext(ServerAPIContext);
};

export const ServerAPIProvider = ({ children }) => {
  const apiObject = React.useMemo(() => new CServerAPI(HOST_API), [HOST_API]);

  return (
    <ServerAPIContext.Provider value={apiObject}>
      {children}
    </ServerAPIContext.Provider>
  );
};

export { CServerAPI };
