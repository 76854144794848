import { Link, useLocation, matchPath } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import rightIcon from './right-nav.svg';
import homeIcon from './home.svg';
import useBreadcrumbsConfig from "./config";
import { useSelector } from "react-redux";

const BreadcrumbsNav = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x); 
  const breadcrumbsConfig = useBreadcrumbsConfig();
  let breadcrumbs = [];
  let currentPath = "";
  const role = useSelector((state) => state.role.role);

  if (location.pathname !== "/dashboard" && (role === 'admin' || role === 'member')) {
    breadcrumbs.push({
      label: <img src={homeIcon} style={{ marginBottom: '3px' }} alt='home' />,
      path: "/dashboard",
    });
  }

  pathnames.forEach((segment, index) => {
    currentPath += `/${segment}`;
    
    const match = breadcrumbsConfig.find((route) => matchPath(route.path, currentPath));
    
    if (match) {
      breadcrumbs.push({
        label: match.label , 
        path: index !== pathnames.length - 1 ? currentPath : "",
      });
    }
  });

  return (
    <Box sx={{ flexGrow: 1, mx: 1 }}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ "& .MuiBreadcrumbs-separator": { mx: 0.5 } }} separator={<img src={rightIcon} alt='navigation path' />}>
        {breadcrumbs.map((crumb, index) =>
          crumb.path ? (
            <Link key={index} to={crumb.path} style={{ textDecoration: "none", color: "#1976d2", fontSize: '14px' }}>
              {crumb.label}
            </Link>
          ) : (
            <Typography style={{ fontSize: '14px' }} key={index} color="text.primary">
              {crumb.label}
            </Typography>
          )
        )}
      </Breadcrumbs>
    </Box>
  );
};

export default BreadcrumbsNav;
