import { SET_LIST_NAME } from './navigationActions';

const initialState = {
  name: '',
};

const navigationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LIST_NAME:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default navigationReducer;