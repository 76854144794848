import { Navigate, Outlet, useRoutes } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
import { mainRoutes, HomePage } from './main';
import { authRoutes } from './auth';
import { authDemoRoutes } from './auth-demo';
import { dashboardRoutes } from './dashboard';
import { componentsRoutes } from './components';
import { lazy } from 'react';
import DashboardLayout from 'src/layouts/dashboard';
import { AuthGuard } from 'src/auth/guard';

// ----------------------------------------------------------------------

const SearchPage = lazy(() => import('src/pages/dashboard/search'));

export default function Router() {
  return useRoutes([
    {

    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout>
            <SearchPage />
          </DashboardLayout>
        </AuthGuard>
      ),
    },

    // Auth routes
    ...authRoutes,
    ...authDemoRoutes,

    // Dashboard routes
    ...dashboardRoutes,

    // Main routes
    ...mainRoutes,

    // Components routes
    ...componentsRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
