import { createStore, combineReducers } from 'redux';
import membersReducer from '../sections/my-agency/membersReducer';
import navigationReducer from './navigationReducer';
import profileReducer from './profileReducer';
import roleReducer from './roleReducer';

const rootReducer = combineReducers({
    members: membersReducer,
    profile: profileReducer,
    role: roleReducer,
    navigation: navigationReducer,
});

const store = createStore(rootReducer);

export default store;
