import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const useBreadcrumbsConfig = () => {
  const location = useLocation();
  const [listName, setListName] = useState(localStorage.getItem("listName"));

  useEffect(() => {
    const updateListName = () => {
      const storedName = localStorage.getItem("listName");
      if (storedName) {
        setListName(storedName);
      }
    };
  
    updateListName();
  
    const interval = setInterval(updateListName, 300);
  
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const currentListName = localStorage.getItem("listName");
    if (currentListName) {
      setListName(currentListName);
    }
  }, [location.pathname]);

  useEffect(() => {
    const handleStorageChange = () => {
      setListName(localStorage.getItem("listName"));
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return [
    { path: "/creators/list/:id", label: listName },
    { path: "/creators/list/:id/creator/:id", label: "Creator Profile" },
    { path: "/my-organization", label: "Organization" },
    { path: "/user/account", label: "Settings" },
    { path: "/search", label: "List" },
    { path: "/creators/creator/:id", label: "Creator Profile" },
    { path: "/creators", label: "Creators" },
    { path: "/creators/search-page", label: "Search Results" },
    { path: "/creators/creator/:id/search-page", label: "Search Results" },
    { path: "/creators/list/:id/search-page", label: "Search Results" },
    { path: "/creators/list/:id/creator/:id/search-page", label: "Search Results" },
    { path: "/upload-creator", label: "Add Creators" },
  ];
};

export default useBreadcrumbsConfig;
