import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import MainLayout from 'src/layouts/main';
import SimpleLayout from 'src/layouts/simple';
import CompactLayout from 'src/layouts/compact';
import { SplashScreen } from 'src/components/loading-screen';
import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';

// ----------------------------------------------------------------------

export const HomePage = lazy(() => import('src/pages/home'));
const Page500 = lazy(() => import('src/pages/500'));
const Page403 = lazy(() => import('src/pages/403'));
const Page404 = lazy(() => import('src/pages/404'));
const ElasticSearchPage = lazy(() => import('src/pages/search/search-page'));
const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));
const SearchPage = lazy(() => import('src/pages/dashboard/search'));
const ListPage = lazy(() => import('src/pages/dashboard/list'));
const AddInfluencerPage = lazy(() => import('src/pages/dashboard/add-influencer'));
const UploadInfluencerPage = lazy(() => import('src/pages/dashboard/upload-influencer'));
const SettingsPage = lazy(() => import('src/pages/dashboard/settings'));
const MyAgency = lazy(() => import('src/pages/dashboard/my-agency'));
const DashboardPage = lazy(() => import('src/pages/dashboard/app'));

// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Outlet />
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: '/', 
        element: <SearchPage />, 
      },
      {
        children: [
          { path: 'search-page', element: <ElasticSearchPage /> },
          {
            path: 'user',
            children: [
              { element: <UserProfilePage />, index: true },
              { path: 'profile', element: <UserProfilePage /> },
              { path: 'cards', element: <UserCardsPage /> },
              { path: 'list', element: <UserListPage /> },
              { path: 'new', element: <UserCreatePage /> },
              { path: ':id/edit', element: <UserEditPage /> },
              { path: 'account', element: <UserAccountPage /> },
            ],
          },
          { path: 'creators/creator/:id', element: <BlankPage /> },
          { path: 'creators/list/:list_id/creator/:id', element: <BlankPage /> },
          { path: 'creators/list/:id', element: <ListPage /> },
          { path: 'creators', element: <SearchPage /> },
          { path: 'creators/search-page', element: <ElasticSearchPage /> },
          { path: 'creators/creator/:id/search-page', element: <ElasticSearchPage /> },
          { path: 'creators/list/:id/search-page', element: <ElasticSearchPage /> },
          { path: 'creators/list/:id/creator/:id/search-page', element: <ElasticSearchPage /> },
          { path: 'add-influencer', element: <AddInfluencerPage /> },
          { path: 'upload-creator', element: <UploadInfluencerPage /> },
          { path: 'my-organization', element: <MyAgency /> },
          { path: 'dashboard', element: <DashboardPage /> },
        ],
      }]
  },
  {
    element: (
      <MainLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </MainLayout>
    ),
    children: [
      {
        children: [
        ],
      },
      {
        path: 'post',
        children: [
        ],
      },
    ],
  },
  {
    element: (
      <SimpleLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </SimpleLayout>
    ),
    children: [
    ],
  },
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },
    ],
  },
];
