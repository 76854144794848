import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// hooks
import { useRouter } from 'src/routes/hooks';
import { useMockedUser } from 'src/hooks/use-mocked-user';
// components
import Logo from 'src/components/logo';
import Scrollbar from 'src/components/scrollbar';
import { usePathname } from 'src/routes/hooks';
import { NavSectionVertical } from 'src/components/nav-section';
//
import { NAV } from '../config-layout';
import { useNavData } from './config-navigation';
import { NavToggleButton, NavUpgrade } from '../_common';
import { MenuItem, Select } from '@mui/material';
import { useSelector } from 'react-redux';
import { useServerAPI } from 'src/api/ServerAPI';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

export default function NavVertical({ openNav, onCloseNav }) {
  const { user } = useMockedUser();
  
  const roles = useSelector((state) => state.role.roles);
  const organizationId = useSelector((state) => state.role.id);

  const serverAPI = useServerAPI();
  const router = useRouter();

  const [value, setValue] = useState(organizationId);

  const pathname = usePathname();

  const lgUp = useResponsive('up', 'lg');

  const navData = useNavData();

  const handleChange = (e) => {
    setValue(e.target.value);
  }

  const selectOrgamization = async (role) => {
    await serverAPI.activateRole(role.id);
    router.push(paths.creators);
    window.location.reload();
  }

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Logo sx={{ mt: 3, ml: 4, mb: 1 }} />
      {roles.length > 1 && <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column' }}>
        <b style={{ fontSize: '18px', color: '#919EAB', marginLeft: '28px', marginBottom: '10px' }}>Organization</b>
        <Select
          sx={{ height: '40px', margin: '10px 14px 0px 14px', padding: '5px 0px' }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          onChange={handleChange}
          value={value}
        >
          {roles?.map(role => {
            return <MenuItem onClick={() => selectOrgamization(role)}
              key={role.entity.id}
              value={role.entity.id}
              style={{ overflow: 'hidden', width: '100%', wordBreak: 'keep-all', padding: '15px 10px' }} >
              {role.entity.name}
            </MenuItem>
          })}
        </Select>
      </div>}

      <NavSectionVertical
        data={navData}
        config={{
          currentRole: user?.role,
        }}
      />

      <Box sx={{ flexGrow: 1 }} />

      <NavUpgrade />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_VERTICAL },
      }}
    >
      <NavToggleButton />

      {lgUp ? (
        <Stack
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.W_VERTICAL,
            borderRight: (theme) => `solid 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.W_VERTICAL,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}

NavVertical.propTypes = {
  onCloseNav: PropTypes.func,
  openNav: PropTypes.bool,
};
